import React from "react";
import withI18next from "../components/withI18next";
import Link from '../components/link';
import {Link as ScrollLink} from "react-scroll";
import Text from '../components/text';
import Layout from "../components/layout";
import PageContentWrapper from "../components/page-content-wrapper";
import ContentRowItem from '../components/content-row-item';
import ReviewScreen from '../components/review-screen';
import ScreenshotsCarousel from '../components/screenshot-carousel';
import ProductPagesWrapper from '../components/product-pages-wrapper';
import HeaderDownloadButtons from '../components/header-download-buttons';
import DownloadScrollButton from '../components/download-scroll-button';
import ImageGQL from '../components/image-gql';
import ScrollUpButton from '../components/scroll-up-button';
import Button from '../components/button';
import Star from '../images/main-page/icons/star.svg';

import AvatarChenWang from '../images/common/review-carousel/chen_wang.png';
import AvatarAndyStephens from '../images/common/review-carousel/quote_2.png';
import AvatarWilliamHolmes from '../images/common/review-carousel/quote_1.png';

class avsFreeRegistryCleaner extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            device: "",
        };

        this.getDevice = this.getDevice.bind(this);
    }

    getDevice(device) {
        this.setState({device: device});
    }

    render() {

        return (<Layout
            getDevice={this.getDevice}
            pageContext={this.props.pageContext}
            t={this.props.t}
            title={this.props.t("Repair Windows registry with AVS Free Registry Cleaner")}
            metaDescription={this.props.t("Scan your pc registry automatically, repair registry errors effectively Improve your computer performance easily with AVS Free Registry Cleaner")}
            metaKeywords={this.props.t("Registry Cleaner, free registry cleaner,best registry cleaner, AVS Registry Cleaner, optimize your computer, computer performance")}
        >
            <ProductPagesWrapper>
                <div className="headerBackground">
                    <ImageGQL className="headerBackgroundImage" imageName="aquamarine_background_main.jpg"
                              style={{position: "absolute"}}/>
                    <PageContentWrapper>
                        <div id="headerContentWrapper" className="headerContentWrapper">
                            <Text as="span" className="flagBackground header">{this.props.t("Free")}
                                <div></div>
                            </Text>
                            <Text color="#ffffff" align="center" className="headerText" lineHeight="65px"
                                  fontSize={68} fontWeight={600}
                                  as="h1">{this.props.t("Free AVS Registry Cleaner")}</Text>
                            <Text color="#ffffff" align="center" className="headerDescription" as="h5"
                                  fontSize={24}>{this.props.t("Make your PC operate clean and fast with Free AVS Registry Cleaner")}</Text>
                            <HeaderDownloadButtons
                                t={this.props.t}
                                mainHref="https://downloads.avs4you.com/distributives/AVSRegistryCleaner.exe"
                                secondaryText="Windows 11/10/8/7/Vista/XP"
                                backgroundColor="orange"
                                mainPadding="12px 37px"
                                margin="auto"
                                id="download-now1_avs-free-registry-cleaner"
                            />
                        </div>
                    </PageContentWrapper>
                    <ImageGQL className="headerImage" imageName="registry_cleaner_header.png"
                              style={{maxWidth: "1564px"}} alt="header-image"/>
                    <ImageGQL className="headerImage mobile" imageName="registry_cleaner_header.png"
                              style={{maxWidth: "1564px"}} alt="header-image-mobile"/>
                </div>
                <ScrollUpButton className="ScrollTopWrapper" ButtonClassName="ScrollTopMain"/>
                <div className="scrollLinksWrapper">
                    <ScrollLink id="scroll-to-overview_avs-free-registry-cleaner" to="overview" spy={true} smooth={true} offset={-70} duration={500}
                                className="scrollToButton overview">
                        <Text color="#666666" align="center">{this.props.t("Overview")}</Text>
                    </ScrollLink>
                    <ScrollLink id="scroll-to-reviews_avs-free-registry-cleaner" to="reviews" spy={true} smooth={true} offset={0} duration={600}
                                className="scrollToButton reviews">
                        <Text color="#666666" align="center">{this.props.t("Reviews")}</Text>
                    </ScrollLink>
                    <ScrollLink id="scroll-to-screenshots-carousel_avs-free-registry-cleaner" to="screenshotsCarousel" spy={true} smooth={true} offset={0} duration={700}
                                className="scrollToButton screentour">
                        <Text color="#666666" align="center">{this.props.t("Screentour")}</Text>
                    </ScrollLink>
                    <ScrollLink id="scroll-to-download-button_avs-free-registry-cleaner" to="downloadButton" spy={true} smooth={true} offset={0} duration={700}
                                className="scrollToButton download">
                        <Text color="#666666" align="center">{this.props.t("Download")}</Text>
                    </ScrollLink>
                </div>

                <PageContentWrapper>
                    <ContentRowItem
                        id="overview"
                        imgLeft={true}
                        imageName="registry_cleaner_slide2.png"
                        headerText={this.props.t("Clean and fix windows registry")}
                    >
                        <Text
                            className="ListItem">{this.props.t("Scan different registry categories like uninstalled entries, shared DLL's, COM/ActiveX and file extensions")}</Text>
                        <Text
                            className="ListItem">{this.props.t("Clean out temporary Internet files, corrupted applications, and trash files")}</Text>
                    </ContentRowItem>

                    <ContentRowItem
                        imgLeft={false}
                        imageName="registry_cleaner_slide3.png"
                        headerText={this.props.t("Secure your PC")}
                    >
                        <Text className="ListItem">{this.props.t("Backup the files for maximum safety")}</Text>
                        <Text
                            className="ListItem">{this.props.t("Schedule time and select file categories for scanning")}</Text>
                        <Text className="ListItem">{this.props.t("Save all crucial registry entries")}</Text>
                    </ContentRowItem>

                    <ContentRowItem
                        imgLeft={true}
                        imageName="registry_cleaner_slide4.png"
                        headerText={this.props.t("Strong program compatibility")}
                    >
                        <Text
                            className="ListItem">{this.props.t("AVS Registry Cleaner is compatible with office-, graphics-, anti-virus- and other software installed on your PC")}</Text>
                        <Text
                            className="ListItem">{this.props.t("AVS Registry Cleaner runs under the latest version of Microsoft Windows 10881 including 64bit version")}</Text>
                    </ContentRowItem>

                </PageContentWrapper>
                <div id="reviews" className="backgroundWrapper">
                    <ReviewScreen
                        reviewsData={[{
                            name: "Chen Wang",
                            avatar: AvatarChenWang,
                            revHeader: this.props.t("Our customers say"),
                            revText: this.props.t("For the price, this is certainly great value for money I mostly use AVS Video Editor and Video Converter and I find them to be both excellent, easy to use with quality results")
                        }, {
                            name: "Andy Stephens",
                            avatar: AvatarAndyStephens,
                            revHeader: this.props.t("Our customers say"),
                            revText: this.props.t("The product is super easy to download and use Anyone can do this in just minutes The company is always supportive I highly recommend")
                        }, {
                            name: "William Holmes",
                            avatar: AvatarWilliamHolmes,
                            revHeader: this.props.t("Our customers say"),
                            revText: this.props.t("I have been using AVS for several years and can say for sure that it is very convenient user friendly and functional")
                        }]}
                    />
                    <Button className="trustButton" style={{margin: "auto", display: "table"}}
                            id="review_avs-free-registry-cleaner"
                            href="https://www.trustpilot.com/review/www.avs4you.com"
                            color="#fff">
                        {this.props.t("Review us on")} <img src={Star} alt="star"
                                                            style={{filter: "brightness(5)"}}/> Trustpilot
                    </Button>
                </div>
                <Text id="screenshotsCarousel" className="carouselHeader"
                      as="h2">{this.props.t("Free AVS Registry Cleaner")}</Text>
                <ScreenshotsCarousel
                    imageNames={["registry_cleaner_slide1.png", "registry_cleaner_slide2.png", "registry_cleaner_slide3.png", "registry_cleaner_slide4.png"]}
                    imageNamesPopup={["registry_cleaner_slide1.png", "registry_cleaner_slide2.png", "registry_cleaner_slide3.png", "registry_cleaner_slide4.png"]}
                    altText={["slideCarousel1", "slideCarousel2", "slideCarousel3", "slideCarousel4"]}

                />
                <PageContentWrapper>
                    <div className="downloadInfoWrapper">
                        <div className="downloadInfoRow">
                            <Text className="downloadInfoParam" color="#000000" fontSize={16} fontWeight={700}
                                  align="right">{this.props.t("File Size")}</Text>
                            <Text className="downloadInfoText" color="#000000" fontSize={16} fontWeight={500}
                                  align="left">9.22 MB</Text>
                        </div>

                        <div className="downloadInfoRow">
                            <Text className="downloadInfoParam" color="#000000" fontSize={16} fontWeight={700}
                                  align="right">{this.props.t("Current Version")}</Text>
                            <Text className="downloadInfoText" color="#000000" fontSize={16} fontWeight={500}
                                  align="left">4.2.2.295</Text>
                        </div>

                        <div className="downloadInfoRow">
                            <Text className="downloadInfoParam" color="#000000" fontSize={16} fontWeight={700}
                                  align="right">{this.props.t("Platforms")}</Text>
                            <Text className="downloadInfoText" color="#000000" fontSize={16} fontWeight={500}
                                  align="left">Windows 11, 10, 8.1, 8, 7, Vista,
                                XP {this.props.t("(no Mac OS/ Linux support)")}</Text>
                        </div>
                    </div>
                    <DownloadScrollButton 
                        className="buttonWrapper"
                        id="download-now2_avs-free-registry-cleaner"
                        href="https://downloads.avs4you.com/distributives/AVSRegistryCleaner.exe"
                        to="headerContentWrapper"
                        idLink="license-agreement_avs-free-registry-cleaner" 
                        toLink="/license-agreement.aspx"
                        textDownload={this.props.t("download now")}
                        textGetCoupon={this.props.t("get 5 coupon code")}
                        textClicking={this.props.t("By clicking the Download button you agree to")}
                        textLink={this.props.t("our End User License Agreement")}
                    />
                </PageContentWrapper>
                <div className="technicalSpecificationWrapper">
                    <PageContentWrapper>
                        <Text className="headerSpecification" color="#000000" fontSize={32} align="center"
                              fontWeight={600}>{this.props.t("Technical specification")}</Text>
                        <div className="specificationGrid">
                            <div>
                                <Text className="requirementsHeader" fontSize={18} color="#000000"
                                      fontWeight={600}>{this.props.t("MINIMUM SYSTEM REQUIREMENTS")}</Text>

                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("Processor")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("AMD Intel Core 2 Duo compatible at 3 GHz or higher")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("RAM")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("2 GB or higher")} </Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("Sound")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("Windows compatible sound card")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("Display")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("32-bit color depth")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("CD recorder")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("32-bit color depth")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("DVD recorder")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("for importing from DVD and creating Video DVD")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700}
                                          align="right">{this.props.t("Video capture device")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("required for capturing")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700}
                                          align="right">{this.props.t("Windows Media Player")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("11 or higher for WMV video playback")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("DirectX")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("Microsoft DirectX 90c or later")}</Text>
                                </div>
                            </div>
                            <div>
                                <Text className="requirementsHeader" fontSize={18} color="#000000"
                                      fontWeight={600}>{this.props.t("RECOMMENDED SYSTEM REQUIREMENTS")}</Text>

                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("Operating system")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("Windows 11/Windows 10/Windows 8.x/Windows 7")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("Processor")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("AMD Intel Core 2 Quad compatible at 24 GHz or higher")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("RAM")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("2 GB or higher")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("Video")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("Windows 10/Windows 8x/Windows 7 compatible video card")}</Text>
                                </div>
                                <div className="systemWrapper">
                                    <Text className="pcComponents" as="span" color="#000000" fontSize={16}
                                          fontWeight={700} align="right">{this.props.t("Sound")}</Text>
                                    <Text as="span" color="#000000" fontSize={16} fontWeight={500}
                                          align="left">{this.props.t("Windows 10/Windows 8x/Windows 7 compatible sound card")}</Text>
                                </div>
                            </div>
                        </div>
                        <Text className="systemInfo" color="#000000" fontSize={16}
                              fontWeight={500}>*{this.props.t("Administrative permissions for program installation and Internet connection for activation")}</Text>
                        <div className="attentionBlock">
                            <Text fontSize={16}
                                  fontWeight={500}>{this.props.t("Please note that AVS4YOU programs do not allow you to copy protected material You may use this software in copying material in which you own the copyright or have obtained permission to copy from the copyright owner")}</Text>
                        </div>
                    </PageContentWrapper>
                </div>
            </ProductPagesWrapper>
        </Layout>);
    }
};
export default withI18next({ns: "common"})(avsFreeRegistryCleaner);
